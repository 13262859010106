import { RouteListApi } from "@/request/api";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/HomeView.vue"),
    redirect: "/monitoring_center/operation_overview",
    children: [
      {
        path: "/Error",
        name: "Error",
        component: () => import("../views/Error.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
];

async function get_router() {
  const token = localStorage.getItem("token");
  if (token) {
    let GetUserRoute = await RouteListApi();
    if (GetUserRoute) {
      GetUserRoute.data.forEach((item) => {
        routes[0].children.push({
          path: item.path,
          component: () => import("../views" + item.path),
        });
      });
    }
  }

  return new VueRouter({
    routes,
    mode: "hash",
  });
}

const router = await get_router();

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.path === "/login" && token) {
    next("/");
    return;
  }
  if (to.path !== "/login" && !token) {
    console.log("执行了这aaaaaaaaaaaa");
    //清除本地存储
    localStorage.removeItem("total_shots_count");
    localStorage.removeItem("station_id");
    next("/login");
    return;
  }
  next();
});

export default router;
