import Vue from 'vue'
import Vuex from 'vuex'
import UserAuth from './UserAuth'
import asideCollapse from './asideCollapse'
import menuList from './menuList'
import TabList from './TabList'
// 引入插件
import createPersistedState from "vuex-persistedstate";
// import webSocket from './webSocket'
// import websocket from './websocket'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    asideCollapse,menuList,UserAuth,TabList
  },
      /* vuex数据持久化配置 */
plugins: [
  createPersistedState({
    // 存储的 key 的key值 
    key: "store",
   paths:['UserAuth']
  })
]
})
