/* eslint-disable no-unused-vars */

import {isShowLog, isShowLogErr} from '@/common.js';
import {GetUserAuthApi} from "@/request/api";

function show_log(...msg){
    if(isShowLog){
        console.log(...msg);
    }
}

function show_log_err(...msg){
    if(isShowLogErr){
        console.log(...msg);
    }
}

function get_date_range(day=0){
    let d = new Date();
    let start = new Date(d.getFullYear(), d.getMonth(),d.getDate()+day).toLocaleDateString().replace(/\//g, '-');
    let end = new Date(d.getFullYear(), d.getMonth(), d.getDate()).toLocaleDateString().replace(/\//g, '-');
    return [start,end];
}

function get_date(day = 0) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + day);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const dayOfMonth = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${dayOfMonth}`;
}

function get_now_datetime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function get_user_auth(store) {
    GetUserAuthApi().then(res => {
        // this.$store.commit.update_userAuth(res.data);
        store.commit('update_userAuth', res.data);
        show_log('UserAuthListApi-res',res);
    }).catch((err) => {
        show_log_err('UserAuthListApi-err',err);
    });
}

export default {
    show_log,
    show_log_err,
    get_date_range,
    get_user_auth,
    get_date,
    get_now_datetime,
}