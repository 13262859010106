export default {
    namespaced: true,
    state: {
        isCollapse: false,
        current_menu: sessionStorage.getItem('current_menu') || null,
        menuList:[]
    },
    mutations: {
        changeIsCollapse(state) {
            state.isCollapse = !state.isCollapse
        },
        updateCurrentMenu(state, id) {
            state.current_menu = id;
            sessionStorage.setItem('current_menu', id);
        },
        setCurrentMenuShow(state, payload) {
            const menuList = state.menuList;
            let currentMenu = menuList.find(item => item.id === currentMenuId);
            while (currentMenu) {
                currentMenu.show = true;
                currentMenu = currentMenu.parent;
            }
        }


    }

}